<template>
  <div class="container">
    <div class="center">
      <h1>kroz</h1>
      <nav>
        <!-- <router-link to="/" style="font-size: 20px;">HOME</router-link> | -->
        <button><router-link to="/play" style="font-size: 20px;">PLAY</router-link></button>&nbsp;
        <button><router-link to="/about" style="font-size: 20px;">ABOUT</router-link></button>
      </nav>
      <p style="vertical-align: bottom !important;">Made with ❤️ by<br /><br /><a
          href="https://madeddu.xyz">madeddu.xyz</a></p>
      <p><a href="https://www.buymeacoffee.com/made2591">buy me a coffe</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  // You can add component-specific data, methods, etc. here
}
</script>