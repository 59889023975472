<template>
    <div class="container">
        <div class="center">
            <h1>about</h1>
            <h3 style="text-align: justify; margin: auto; width: 80%;">This is a textual game in preview. The game
                details are easily accesible from the console, but it's much more fun trying to solve it without getting the
                content. I'm going to update the game from time to time to support new feature. At the moment, no local
                storage or anything similar is available. I most probably add in the near future the chance to
                save.</h3><br />
            <h3>Click BACK then PLAY to start and enjoy the game!</h3>
            <br />
            <nav>
                <button><router-link to="/" style="font-size: 20px;">BACK</router-link></button>&nbsp;
                <button><router-link to="/roadmap" style="font-size: 20px;">ROADMAP</router-link></button>
            </nav>
            <p style="vertical-align: bottom !important;">Made with ❤️ by<br /><br /><a
                    href="https://madeddu.xyz">madeddu.xyz</a></p>
            <p><a href="https://www.buymeacoffee.com/made2591">buy me a coffe</a></p>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'AboutView',
    // You can add component-specific data, methods, etc. here
}
</script>