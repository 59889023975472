import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView'
import GameView from '../views/GameView'
import RoadMap from '../views/RoadMap'
import AboutView from '../views/AboutView'

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/play',
    name: 'GameView',
    component: GameView
  },
  {
    path: '/roadmap',
    name: 'RoadMap',
    component: RoadMap
  },
  {
    path: '/about',
    name: 'AboutView',
    component: AboutView
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
