<template>
  <div class="container">
    <div class="center">
      <nav>
        <button><router-link to="/" style="font-size: 20px;">HOME</router-link></button>&nbsp;
        <button><router-link to="/about" style="font-size: 20px;">ABOUT</router-link></button>
      </nav>
    </div>
    <div ref="titleBox"></div>
    <br>
    <div ref="descBox"></div>
    <br>
    <input ref="inputCommand" v-model="command" @keyup.enter="checkCommand" placeholder="Type 'help' for assistance..."
      style="width: 100%;" />
    <br>
    <br>
    <div ref="errorBox" class="errorBox"></div>
    <div ref="infoBox" :class="{ 'infoBox': !finished, 'finishedBox': finished }"></div>
  </div>
</template>

<script>
import * as utils from './utils/utils';
import * as logic from './utils/logic';

export default {
  name: 'GameView',
  data() {
    return {
      command: '',
      game: utils.game(),
      actualRoom: null,
      finished: false,
      inventory: [],
      level: 1,
      lastItemSeen: null,
      titleBox: null,
      descBox: null,
      errorBox: null,
      infoBox: null,
      actionAllowed: false
    };
  },
  mounted() {
    this.game = utils.game(this.level);
    this.actualRoom = utils.firstRoom(this.game);
    this.titleBoxOutput = [utils.prefix(this.actualRoom["name"])];
    this.descBoxOutput = [utils.prefix(this.actualRoom["description"])];
    this.titleBox = utils.prepareTypedBox(this.$refs.titleBox, [utils.prefix(this.actualRoom["name"])]);
    this.descBox = utils.prepareTypedBox(this.$refs.descBox, [utils.prefix(this.actualRoom["description"])], 1500);
    this.errorBox = utils.prepareTypedBox(this.$refs.errorBox);
    this.infoBox = utils.prepareTypedBox(this.$refs.infoBox);
  },
  methods: {
    async checkCommand() {
      utils.updateTypedBox(this.infoBox);
      this.actionAllowed = false;
      logic.changeLevel(this.$data);
      logic.moveCommands(this.$data);
      logic.lookupCommands(this.$data);
      logic.getCommands(this.$data);
      logic.inventoryCommands(this.$data);
      logic.helpCommands(this.$data);
      if (!this.actionAllowed) {
        utils.updateTypedBox(this.errorBox, [utils.prefix(utils.commandNotAllowedMsg())]);
      }
      this.command = '';
      return;
    }
  },
  beforeUnmount() {
    this.titleBox.destroy();
  }
};
</script>