import * as utils from './utils';

export function changeLevel(data) {
    if (data.actualRoom["trap_room"]) {
        utils.updateTypedBox(data.infoBox, [utils.prefix(data.actualRoom["error"])]);
        data.command = '';
        return;
    }
    if (data.finished == true && data.command.toLowerCase().includes(data.level + 1)) {
        data.level = data.level + 1;
        data.game = utils.game(data.level);
        if (data.game == null) {
            utils.updateTypedBox(data.infoBox, [utils.prefix(`Good job! You terminate the game! Refresh the page to restart :)`)]);
            data.command = '';
            return;
        }
        data.actualRoom = utils.firstRoom(data.game);
        data.inventory = [];
        utils.updateTypedBox(data.titleBox, [utils.prefix(data.actualRoom["name"])]);
        utils.updateTypedBox(data.descBox, [utils.prefix(data.actualRoom["description"])]);
        utils.updateTypedBox(data.errorBox);
        data.finished = false;
        data.actionAllowed = true;
        data.command = '';
        return;
    }
}

export function moveCommands(data) {
    for (const action of utils.moveCommands()) {
        if (data.command.toLowerCase().includes(action) && action in data.actualRoom["moves"]) {
            let required_item = data.actualRoom["required_item"];
            if (required_item == undefined || data.inventory.indexOf(required_item) > -1) {
                console.log(action);
                data.actualRoom = utils.getRoom(data.game, data.actualRoom["moves"][action]);
                utils.updateTypedBox(data.titleBox, [utils.prefix(data.actualRoom["name"])]);
                utils.updateTypedBox(data.descBox, [utils.prefix(data.actualRoom["description"])]);
                utils.updateTypedBox(data.errorBox);
                console.log(utils.game(data.level + 1));
                if (data.actualRoom["end_room"] && utils.game(data.level + 1) == null) {
                    utils.updateTypedBox(data.infoBox, [utils.prefix(`Good job! You terminate the game! Refresh the page to restart :)`)]);
                    data.actionAllowed = true;
                    data.finished = true;
                    data.command = '';
                    return;
                } else if (data.actualRoom["end_room"]) {
                    utils.updateTypedBox(data.infoBox, [utils.prefix(`Good job! You terminate the first level! Type ${data.level + 1} to move to the next level...`)]);
                    data.actionAllowed = true;
                    data.finished = true;
                    return;
                }
                data.actionAllowed = true;
                data.command = '';
                return;
            } else {
                console.log(action);
                utils.updateTypedBox(data.errorBox, [utils.prefix(data.actualRoom["error"])]);
                data.actionAllowed = true;
                data.command = '';
                return;
            }
        }
    }
}

export function lookupCommands(data) {
    for (const action of utils.lookupCommands()) {
        if (data.command.toLowerCase().includes(action)) {
            utils.updateTypedBox(data.errorBox);
            if (data.actualRoom["items"].length > 0) {
                data.lastItemSeen = data.actualRoom["items"][0];
                utils.updateTypedBox(data.infoBox, [utils.prefix(utils.lookupResultMsg(data.lastItemSeen))]);
                data.actionAllowed = true;
                break;
            } else {
                utils.updateTypedBox(data.infoBox, [utils.prefix("There's nothing interesting here...")]);
                data.actionAllowed = true;
                data.command = '';
                return;
            }
        }
    }
}

export function getCommands(data) {
    for (const action of utils.getCommands()) {
        if (data.command.toLowerCase().includes(action) && data.lastItemSeen != null) {
            utils.updateTypedBox(data.errorBox);
            data.inventory.push(data.lastItemSeen);
            data.actualRoom["items"] = data.actualRoom["items"].filter(obj => { return obj !== data.lastItemSeen });
            utils.updateTypedBox(data.infoBox, [utils.prefix(utils.itemGotMsg(data.lastItemSeen))]);
            data.lastItemSeen = null;
            data.actionAllowed = true;
            break;
        }
    }
}

export function inventoryCommands(data) {
    for (const action of utils.inventoryCommands()) {
        if (data.command.toLowerCase().includes(action)) {
            utils.updateTypedBox(data.errorBox);
            utils.updateTypedBox(data.infoBox, [utils.prefix(utils.inventoryMsg(data.game, data.inventory))]);
            data.actionAllowed = true;
            break;
        }
    }
}

export function helpCommands(data) {
    for (const action of utils.helpCommands()) {
        if (data.command.toLowerCase().includes(action)) {
            utils.updateTypedBox(data.errorBox);
            utils.updateTypedBox(data.infoBox, [utils.prefix(utils.helpMsg())], 0, 10);
            data.actionAllowed = true;
            break;
        }
    }
}