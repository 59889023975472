
import HomeView from './views/HomeView.vue';
<template>
  <HomeView></HomeView>
  <router-view />
</template>

<style>
button {
  color: darkgray;
  background-color: transparent;
}

body {
  background-color: #222831 !important;
  border: 1px solid darkgrey !important;
  font-family: 'Courier New', Courier, monospace !important;
  color: darkgrey !important;
  padding: 8px;
  font-size: 17px;
}

input,
a {
  font-family: 'Courier New', Courier, monospace;
  background-color: transparent;
  border: 0px solid darkgray;
  color: darkgray;
  padding: 8px;
  font-size: 16px;
}

::placeholder {
  color: darkgray;
  opacity: 1;
  /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: darkgray;
}

input:focus {
  outline: none;
  border-color: darkgray;
}

.container {
  border-color: darkgray;
  border-style: double;
  font-weight: bold;
  padding: 1%;
  min-height: 92vh;
}

.errorBox {
  color: lightcoral !important;
}

.infoBox {
  color: lightblue !important;
}

.finishedBox {
  color: lightcyan !important;
}

.center {
  text-align: center;
  margin: 20px;
}
</style>
