<template>
    <div class="container">
        <div class="center">
            <h1>roadmap</h1>
            <h3>- introduce the chance to save the game in local browser store;</h3>
            <h3>- add a timer to solve some of the level;</h3>
            <h3>- enable explicit use of items;</h3>
            <h3>- add a chart with best timing results;</h3>
            <h3>- make the project an opensource project;</h3>
            <h3>- introduce life points;</h3>
            <h3>- introduce save diamods;</h3>
            <h3>- new levels and deep history;</h3>
            <br />
            <!-- <h3>If you want to support to project you can <a href="https://www.buymeacoffee.com/made2591">buy me a coffe</a>
            </h3> -->
            <nav>
                <button><router-link to="/about" style="font-size: 20px;">BACK</router-link></button>&nbsp;
                <button><router-link to="/" style="font-size: 20px;">HOME</router-link></button>
            </nav>
            <p style="vertical-align: bottom !important;">Made with ❤️ by<br /><br /><a
                    href="https://madeddu.xyz">madeddu.xyz</a></p>
            <p><a href="https://www.buymeacoffee.com/made2591">buy me a coffe</a></p>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'RoadMap',
    // You can add component-specific data, methods, etc. here
}
</script>