import Typed from 'typed.js';
import database from '../../config/game.json';

export function game(lvl = 1) {
    return database["levels"][lvl] || null;
}

export function moveCommands() {
    return ['north', 'south', 'east', 'west'];
}

export function inventoryCommands() {
    return ['inventory'];
}

export function helpCommands() {
    return ['help'];
}

export function lookupCommands() {
    return ['search', 'look'];
}

export function getCommands() {
    return ['get', 'gather', 'take'];
}

export function useCommands() {
    return ['use', 'open', 'climb'];
}

export function prefix(msg = '') {
    return "# " + msg;
}

export function getRoom(game, id) {
    for (let roomIndex in game["rooms"]) {
        let room = game["rooms"][roomIndex];
        if (room["id"] == id) {
            return room
        }
    }
}

export function firstRoom(game) {
    return getRoom(game, 1)
}

export function commandNotAllowedMsg() {
    const msgs = [
        "You cannot do that",
        "It's not allowed",
    ]
    return msgs[Math.floor(Math.random() * msgs.length)];
}

export function lookupResultMsg(item) {
    const msgs = [
        `There's a ${item} over there...`,
        `Oh oh! You found a ${item}...`,
    ]
    return msgs[Math.floor(Math.random() * msgs.length)];
}

export function itemGotMsg(item) {
    const msgs = [
        `You got the ${item}!`,
        `${item} got`,
    ]
    return msgs[Math.floor(Math.random() * msgs.length)];
}

export function helpMsg() {
    let msg = `This is a textual adventure. Is still in beta so... be careful and text me for bugs at matteo.madeddu[at]@gmail.com.<br/>${prefix()} You can move using cardinal points name (i.e. go to north, or north).<br/>${prefix()} You can search for something in the room with standard expression (i.e. search for something, have a look around).<br/>${prefix()} You can collect items (i.e. get the potion).<br/>${prefix()} You can open your inventory (i.e. open inventory, or inventory) to see what items you collected.<br/>${prefix()} Once you collected the item, you're automatically able to use them.<br/><br/>${prefix()} One suggestion... choose carefully, there's the chance to get stucked and the only way to go ahead is to refresh the page.<br/><br/>${prefix()} Finally, you can type 'help' to show this message. Enjoy the game`;
    return msg;
}

export function inventoryMsg(game, inventory) {
    let msg = "Your inventory contains: ";
    if (inventory.length == 0) {
        msg = "Your inventory is empty";
    } else {
        for (const i in inventory) {
            msg += `<br/>${prefix("- " + inventory[i])}: ${getItemDescription(game, inventory[i])}`;
        }
    }
    return msg;
}

export function getItemDescription(game, item) {
    // console.log(item);
    // console.log(game()["items"]);
    return game["items"].filter(obj => { return obj["name"] == item })[0]["description"];
}

export function prepareTypedBox(ref, outputs = [''], startDelay = 0, typeSpeed = 10) {
    return new Typed(ref, {
        strings: outputs,
        typeSpeed: typeSpeed,
        startDelay: startDelay,
        contentType: 'html',
        showCursor: false,
    });
}

export function updateTypedBox(box, outputs = [''], startDelay = 0, typeSpeed = 10) {
    box.strings = outputs;
    box.typeSpeed = typeSpeed;
    box.startDelay = startDelay;
    box.reset(true);
}